"use client"

import React, { useCallback, useState } from "react"

import { useTranslations } from "next-intl"

import { useDMToast } from "@supernovaio/dm"

import {
  createErrorToastEvent,
  useSegment,
} from "@supernovaio/cloud/features/segment"
import { useBillingPlanRoute } from "@supernovaio/cloud/hooks/routing/useBillingPlanRoute"
import { useParams } from "@supernovaio/cloud/hooks/useParams"
import { ToastSupportButton } from "@supernovaio/cloud/ui/components/ToastSupportButton"
import { getClientSdk } from "@supernovaio/cloud/utils/data/getClientSdk"

import { enrichLinkWithOrigin } from "../../../../utils/links"
import { BillingPlanPageRouteDestination } from "../../../../utils/routing/types"

type HandleCreateSubscriptionArguments = {
  priceId: string
  seatCount?: number
}

export function useCreateSubscription() {
  const t = useTranslations("Subscription.Toast")
  const toast = useDMToast()
  const { trackEvent } = useSegment()
  const { wsId } = useParams()
  const [isPending, setIsPending] = useState<boolean>(false)

  const successUrl = useBillingPlanRoute(
    BillingPlanPageRouteDestination.success
  )
  const failedUrl = useBillingPlanRoute(BillingPlanPageRouteDestination.failed)

  const handleCreateSubscription = useCallback(
    async ({ priceId, seatCount }: HandleCreateSubscriptionArguments) => {
      if (!wsId || !successUrl || !failedUrl) {
        return undefined
      }

      setIsPending(true)

      const sdk = await getClientSdk()

      try {
        const response = await sdk.workspaces.createCheckoutSession(
          wsId,
          priceId,
          enrichLinkWithOrigin(successUrl),
          enrichLinkWithOrigin(failedUrl),
          seatCount
        )

        return {
          redirectUrl: response,
        }
      } catch (e) {
        const toastId = toast.show({
          preset: "error",
          text: t("errorCreateSession"),
          actionsSlot: <ToastSupportButton getToastId={() => toastId} />,
        })

        trackEvent(
          createErrorToastEvent({
            failedAction: "subscription_create",
            domain: "workspace",
          })
        )
      } finally {
        setIsPending(false)
      }

      return undefined
    },
    [failedUrl, successUrl, t, toast, wsId, trackEvent]
  )

  return {
    handleCreateSubscription,
    isPending,
  }
}
