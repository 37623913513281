import {
  BrandRouteDestinationType,
  BrandRouteType,
  BrandRouteWithDestinationType,
} from "../../utils/routing/types"

import { useCurrentBrandRoute } from "./useBrandRoute"

export function useCurrentBrandRouteWithDestination(
  destination: BrandRouteDestinationType
): BrandRouteWithDestinationType | undefined {
  const brandRoute = useCurrentBrandRoute()

  if (!brandRoute) return undefined

  return `${brandRoute}/${destination}`
}

export function brandRouteWithDestination(
  route: BrandRouteType,
  destination: BrandRouteDestinationType
): BrandRouteWithDestinationType {
  return `${route}/${destination}`
}
